* {
  margin: 0;
  padding: 0;
}

.pdf-container {
  width: 100%;
  height: 400px;
  background-color: #e4e4e4;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hoverText:hover {
  color: #00b8a9;
}

.error-msg {
  color: red;
  font-size: 14px;
  font-weight: 600;
}
.header {
  margin-left: -10px;
  font-size: 25px;
  font-weight: 600;
}
.image {
  margin-top: -20px;
  margin-left: -20px;
}
/* ---------------------------------------------- Invoice table style ------------------------------- */
