.toolTip {
  position: absolute;
  display: none;
  min-width: 80px;
  height: auto;
  background: none repeat scroll 0 0 "red";
  border: 1px solid #6f257f;
  padding: 14px;
  text-align: center;
}

.top_cost_container {
  fill: #000;
}

.faded > g > rect{
  opacity: 0.5;
}

.faded > g > rect:hover {
  opacity: 1 !important;
}

#stacked_bars_container > g > rect {
  transition: .2s all;
}
