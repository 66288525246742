.blue-button-back {
  padding: 10px;
  background-color: #5dbcee;
  border-radius: 5px;
  cursor: pointer;
}

.blue-button-back :hover {
  background-color: #5dbcee;
}

.blue-button-text {
  color: white;
  /*padding: 15px 0px 5px 0px;*/
  user-select: none;
}

.icon-background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 2px solid #e8e8e8;
  border-radius: 5px;
  background-color: #fff;
}

.connection-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background-color: #fafafa;
}

.sticky-wrapper {
  position: relative;
  height: 2.6rem; /* We need to change this value */
  /*display: flex;*/
  /*flex-direction: row;*/
  /*justify-content: space-between;*/
  /*margin-top: 20px;*/
}

.sticky .sticky-inner {
  position: fixed;
  top: 80px;
  /*left: 0px;*/
  right: 30px;
  z-index: 1;
  /*display: flex;*/
  /*width: fit-content;*/
  /*margin-left: 1050px;*/
  margin-right: -10px;
}
.sticky .sticky-inner2 {
  position: fixed;
  top: 80px;
  left: 80px;
  right: 10px;
  z-index: 1;
  width: fit-content;
  display: flex;
  background-color: #ffffff;
}
.sticky-dashboard .sticky-inner-dashboard {
  position: fixed;
  top: 60px;
  /*left: 0px;*/
  right: 30px;
  z-index: 1;
  /*display: flex;*/
  /*width: fit-content;*/
  /*margin-left: 1050px;*/
  margin-right: -10px;
}

.sticky-aws-daterange .sticky-inner-aws-daterange {
  position: fixed;
  top: 48px;
  /*left: 0px;*/
  right: 30px;
  z-index: 1;
  /*display: flex;*/
  /*width: fit-content;*/
  /*margin-left: 1050px;*/
  margin-right: -10px;
}

.sticky-aws-dropdown .sticky-inner-aws-dropdown {
  position: fixed;
  top: 90px;
  /*left: 0px;*/
  right: 30px;
  z-index: 1;
  /*display: flex;*/
  /*width: fit-content;*/
  /*margin-left: 1050px;*/
  margin-right: -10px;
}

.calendar-date {

}

.month-calendar-cost {

  font-size: 14px;
  font-weight: bold;
}

.month-calendar-text {

}

.day-calendar-cost {
    font-size: 12px;
  font-weight: bold;
}

.day-calendar-text {
    font-size: 12px;
}

.calendar-week-container{
  background-color: #4051b54f;
  display: flex;
  flex: 1;
  font-size: 12px;
}

.calendar-date-bg-1{
  background-color: #f9faff;
}

.calendar-date-bg-2{
  background-color: #eceefe;
}
