.userMng {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.userMng th,
.userMng td {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
}
.userMng th {
    background-color: #cfdac8;
}

/* .userMng tr:nth-child(even) {
    background-color: #dddddd;
} */
