/*@font-face {*/
/*  font-family: Poppins_Regular;*/
/*  font-style: normal;*/
/*  src: local(Poppins), url(fonts/Poppins-Regular.ttf) format("truetype");*/
/*}*/
@font-face {
  font-family: Poppins_Regular;
  font-style: normal;
  src: local(Poppins), url(fonts/Poppins-Regular.otf) format("opentype");
}

@font-face {
  font-family: Poppins_SemiBold;
  font-style: normal;
  src: local(Poppins SemiBold),
    url(fonts/Poppins-SemiBold.otf) format("opentype");
}

@font-face {
  font-family: SF_Pro_Text;
  font-style: normal;
  src: local(SF Pro Text), url(fonts/SFProText-Regular.ttf) format("truetype");
}

@font-face {
  font-family: SF_Compact_Display;
  font-style: normal;
  src: local(SF Compact Display),
    url(fonts/SFCompactDisplay-Regular.otf) format("opentype");
}

html * {
  font-family: Poppins_Regular;
}

.heading {
  font-family: Poppins-Regular;
}

/*Animated App Logo */
/* WebKit and Opera browsers */
.image {
  /*position: absolute;*/
  /*top: 50%;*/
  /*left: 50%;*/
  /*width: 120px;*/
  /*height: 120px;*/
  /*margin:-60px 0 0 -60px;*/
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotateY(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotateY(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
/*Animated App Logo Upto here */

.disabled-field {
  color: grey;
}

.wrapper {
  width: 100%;
  position: fixed;
  z-index: 10;
  background: inherit;
}

.bottom-wrapper {
  width: 100%;
  padding-top: 92px;
  z-index: 5;
  overflow: auto;
}

.flex-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectable {
  background-color: #fff;
  padding: 5px;
  text-align: center;
  color: #3f51b5db;
  cursor: pointer;
}

.selectable:hover {
  background-color: #3f51b5db;
  color: white;
}
/*************************Budgets Popup**********************/
/* Popup style */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: "2";
}

.box {
  position: relative;
  width: 50%;
  margin: 0 auto;
  height: auto;
  max-height: 80%;
  /* margin-top: calc(100vh - 85vh - 10px); */
  top: 12%;
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}

.close-icon {
  content: "x";
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: 25%;
  /* top: calc(100vh - 85vh - 33px); */
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

#costunify_app_logo {
  height: 100px;
  width: 100px;
  margin-bottom: 10px;
}

#costunify_app_logo_hover {
  background-color: #7575758f;
  height: 100px;
  width: 100px;
  position: absolute;
  opacity: 0;
  transition: 0.5s ease;
}

.costunify_overlay:hover #costunify_app_logo_hover {
  opacity: 1;
  cursor: pointer;
}

.axis path,
.axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

.x.axis path {
  display: none;
}

/* 13. Basic Styling with CSS */

/* Style the lines by removing the fill and applying a stroke */
.line {
  fill: none;
  stroke: #ffab00;
  stroke-width: 3;
}

.overlay {
  fill: none;
  pointer-events: all;
}

/* Style the dots by assigning a fill and stroke */
.dot {
  fill: #ffab00;
  stroke: #fff;
}

.focus circle {
  fill: none;
  stroke: steelblue;
}

#popover-contained {
  z-index: 1111;
}

.dropdownGroup{
  padding: 10px;
  cursor: pointer;
}
.dropdownGroup:hover{
  background: #3f51b5db;
  color: white;
 }

.link-button {
  color: gray;
  cursor: pointer;
  margin: 5px;
  justify-self: flex-end;
}
.link-button:hover{
    color: #3f51b5;
  }

.secondary-table-item{
  white-space: nowrap;
  font-size: 11px;
  color: #7f7f7f;
}

.highlighted-table-cell {
  background: #96a38c24;
}

.line-heighted-table-cell {
  line-height: unset !important;
  background: #96a38c24;
}

.line-heighted-tc {
  line-height: unset !important;
}

.data-grid-header {
  background: #1f1f1f;
}

.MuiDataGrid-columnHeaderWrapper {
  background: #f1f1f1;
}

.MuiDataGrid-columnSeparator{
  display: none !important;
}
