.hhover {
  cursor: pointer;
}
.hhover:hover {
  color: steelblue;
  text-decoration: underline;
}
.addAccount:hover {
  cursor: pointer;
  color: steelblue;
  text-decoration: underline;
}
.addAccountDiv {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
}
