/* Slideshow */

.slideshow {
  margin: 0 auto;
  overflow: hidden;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;
  width: 100%;
}

/* Buttons */
.paperBottom {
  /* border-bottom: 5px solid rgba(63, 81, 181, 0.86); */
  border-bottom: 5px solid rgba(60, 5, 114, 0.755);
}

.slideshowDots {
  /* text-align: center; */
  display: flex;
  margin-top: -2.5px;
}

/* .slideshowDot {
  display: inline-block;
  margin-right: 10px;
  padding: 0px 5px 0px 5px;
  cursor: pointer;
  color: #c4c4c4;
} */

.slideshowDot.active {
  color: #6a0dad;
  border-color: #6a0dad;
}
