.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.root1 {
  color: green;
  margin-left: 20px;
  font-weight: bold;
}
.id {
  color: gray;
  margin-left: 23px;
  font-size: 12px;
}
.name {
  font-weight: bold;
}
