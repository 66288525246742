.axis {
  font: 13px normal;
}
.axis path,
.axis line {
  fill: none;
  stroke: #393e46;
  stroke-width: 1px;
  shape-rendering: crispEdges;
}
.toolTipVerticalBarChart {
  position: absolute;
  display: none;
  min-width: 80px;
  height: auto;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  border-radius: 5px;
  z-index: 10px;
  padding: 10px;
  text-align: center;
}
.title {
  font-weight: bold;
}
.totalCost {
  font-weight: bold;
}
