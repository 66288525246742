
.totalCost{
    display: flex;
    justify-content: flex-end;
}
.carouselText{ 
    color: black;
    margin-top: -5px;
}
.carouselValue{
    color: black;
    font-weight: bold;
    font-size:30px;
}
.centerDiv{
    display: flex;
    align-items: center;
    justify-content: center;
}
.typoMargin{
    margin-top:8px;
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  text-align: center;
  font-size: 14px;
  padding: 2px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center;
  background-color: hsl(120, 1%, 28%);
  color: white;
  position: sticky;
  top: 0;
}
