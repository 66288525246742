.loader {
    left: 50%;
    margin-left: 40%;
  }
.center {
    margin-left:10%; 
    
    
  }
.padding {
    padding-top: 9 0%;
    padding-right: 30px;
    padding-bottom: 50px;
    padding-left: 80px;
    width: 100%
  }
  .listbutton{
    margin-left: 80%;
    
   
  }
.myTable { border:none; 
  background-color:rgb(190, 216, 250);
}
.tablehome{
  border: 1px solid black;
  border-collapse: collapse;
  
}



  