.body_div {
    /*margin-left:30%;*/
    /*margin-top: 30%;*/
}

@keyframes scaling{
    0%, 100%{
        transform: scale(0.2);
        background-color: #FFFFFF;
    }
    /*40%{*/
    /*    transform: scale(1);*/
    /*    background-color: #07DEFF;*/
    /*}*/
    50%{
        transform: scale(1);
        background-color: #3f51b5;
    }
}

custom_div {
    height: 40px;
    width: 40px;
    /*border-radius:50%;*/
    transform: scale(0);
    background-color:red;
    animation: scaling 2.5s ease-in-out infinite;
    display: inline-block;
    margin:.1rem;
}

custom_div:nth-child(0){
    animation-delay:0s;
}
custom_div:nth-child(1){
    animation-delay:0.2s;
}
custom_div:nth-child(2){
    animation-delay:0.4s;
}
custom_div:nth-child(3){
    animation-delay:0.6s;
}
custom_div:nth-child(4){
    animation-delay:0.8s;
}
custom_div:nth-child(5){
    animation-delay:1s;
}
